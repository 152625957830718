@import '../../theme/palette';
@import '../../theme/text';
@import '../../theme/table';

:global(#app) {
  .sortIcon {
    cursor: pointer;
  }

  .btn {
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    background-color: $grey4;
    border: 1px solid $grey4;
    transition: all 0.4s;
    span {
      @include button;
      font-size: 14px;
    }
  }
  .btnRemove {
    color: $secondary_red_MPB;
    border-color: $secondary_red_MPB;
    background-color: $other_white1;
    &:hover {
      background-color: $secondary_red_MPB;
      color: $other_white1;
    }
  }
}

.tableWrapper {
  box-shadow: 2px 0px 9px rgba(40, 40, 40, 0.25);
  border-radius: 8px;
  scrollbar-width: none;
  position: relative;
  &::-webkit-scrollbar {
    display: none;
  }

  .table {
    width: 100%;
    border-collapse: collapse;
    border-radius: 8px 8px 0 0;
    .fixedHeader {
      .headerTR {
        height: 3.5rem;
      }
    }
  }
}

.headerTH {
  @include table_headerTH;
}
.headerTitleBlock {
  @include table_headerTitleBlock;
}

.choiceTH {
  width: 5%;
}

.typeTH {
  width: 20%;
}

.assignedTH {
  width: 20%;
}

.startDateTH {
  width: 15%;
}

.sessionTH {
  width: 10%;
}

.progressTH {
  width: 10%;
}

.coachTH {
  width: 10%;
}

.evaluationTH {
  width: 10%;
}

/*BUTTON */
.buttonBlock {
  margin: 0.8rem 0;
}

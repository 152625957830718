@import '../../theme/palette';
@import '../../theme/text';
@import '../../theme/sharedForm';
@import '../../theme/button';
@import '../../theme/responsive';

:global(#app) {
  .editBtn {
    @include sharedForm_editBtn;
  }
  .btnCancel {
    margin-top: 1rem;
    @include sharedForm_btnCancel;
    width: $form_save_cancel_btn_width;
    @include Responsive(MD) {
      margin-top: 0;
    }
  }
  .btnSave {
    @include sharedForm_btnSave;
    width: $form_save_cancel_btn_width;
    @include Responsive(MD) {
      margin-bottom: 0;
    }
  }

  .activeSave {
    @include sharedForm_activeSaveBtn;
  }

  .customDropdown {
    opacity: 100;
    border: none;
    padding: 0;
    height: auto;
    min-height: auto;
    min-width: 8rem;
    input {
      padding: 0;
    }
    i {
      padding: 0;
    }
  }

  .pencilIcon {
    @include sharedForm_pencilIcon;
  }
}

.formWrapper {
  margin-top: 1.2rem;
  position: relative;
  @include sharedForm_formWrapper;
  padding-bottom: 3.5rem;
  padding-right: 0rem;
}

.formContainer {
  display: flex;
  @include Responsive(MD) {
    display: block;
  }
}

.formMediaResource {
  width: 70%;
  .formField {
    @include sharedForm_formField;

    .labelWrapper {
      min-width: 170px;
    }

    .label {
      @include sharedForm_label;
    }
    .sup {
      @include sharedForm_sup;
    }

    .inputField {
      @include sharedForm_inputField;
    }
    .timeInputField {
      width: 35px;
      text-align: center;
    }
  }
  .specialLabelWrapper {
    min-width: 240px !important;
  }

  @include Responsive(MD) {
    width: 95%;
  }
}

.accessLinkText {
  font-family: $primary_font1;
  color: $primary_blue1;
  cursor: pointer;
  &:hover {
    color: $primary_blue1;
  }
}

.editText {
  @include sharedForm_editText;
}

.rightGroup {
  @include sharedForm_rightGroup;
  justify-content: flex-start;
  width: 30%;
  padding-top: 3rem;
  @include Responsive(MD) {
    width: 95%;
    flex-direction: row;
    justify-content: flex-end;
  }
}

.textMessage {
  @include sharedForm_dangerMessage;
}

.fileInstructionText {
  color: rgb(118, 118, 118) !important;
}

@import "../../theme/palette";
@import "../../theme/text";
@import "../../theme/responsive";

.wrapper {
  min-height: 100vh;
  overflow: hidden;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  .formWrapper {
    min-width: 400px;
    
    text-align: center;
    @include Responsive(MD) {
      margin-top: 30%;
    }
    @include Responsive(SM) {
      margin-top: 10%;
      min-width: 310px;
    }
    @include Responsive(XS) {
      margin-top: 10%;
      min-width: 310px;
    }
  }

  .imgBackground {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .formPositionLeft {
    box-shadow: 5px 5px 8px 7px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    width: 15%;
    margin-top: 10%;
    margin-left: 5%;
  }
  .formPositionCenter {
    width: 11%;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-40%, -50%);
  }
  .absoluteCenter {
    width: 100%;
    position: relative;
    transform: translate(0, 0);
    left: 0;
  }
}
.branchMiddle {
  width: 80%;
  margin: 0 auto;
  margin-top: 1rem;
  margin-bottom: 3rem;
}
.branchLeft {
  width: 16%;
  min-width: 150px;
  margin-top: 10px;
  margin-left: 10px;
}

:global(#app) {
  .clearBorder {
    border: none;
    box-shadow: none;
  }
  .adjustBgTransparent {
    opacity: 0.8;
  }
}

.bg1_medium{
  background-image: url("../../assets/images/506166cfb9872f7637adfae814a57cd8_1280x854.jpg");
}
.bg1_large {
  background-image: url("../../assets/images/506166cfb9872f7637adfae814a57cd8_1920x1280.jpg");
}

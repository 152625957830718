@import "../../theme/palette";
@import "../../theme/text";
@import "../../theme/responsive";
@import "../../theme/sharedForm";
@import "../../theme/button";

:global(#app) {
  .uploadIcon {
    font-size: 4rem;
    color: $grey3;
  }

  .customedSegment {
    border: none;
    box-shadow: none;
    padding-left: 0;
    padding-right: 0;
  }
}

.uploadWrapper {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 14rem;
  border: 3px dashed $grey4;
  position: relative;
  border-radius: 8px;
  &:focus {
    outline: none;
  }

  .uploadDesc {
    @include head2;
    font-size: 24px;
    color: $grey2;
    text-align: center;
  }
  .errorMessage {
    font-size: 14px;
    color: $other_black1;
    text-align: center;
  }
  .textClickable {
    @include head2;
    font-size: 24px;
    color: $secondary_blue2;
    position: relative;
    cursor: pointer;
    &::after {
      content: "";
      height: 1.5px;
      width: 100%;
      background-color: $secondary_blue2;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
  .textIllegalFile {
    @include head2;
    font-size: 16px;
    color: $secondary_blue2;
    position: relative;
    cursor: pointer;
    &::after {
      content: "";
      height: 1.5px;
      width: 100%;
      background-color: $secondary_blue2;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }

  .fileName {
    @include head2;
    color: $secondary_blue2;
    font-size: 24px;
    word-break: break-all;
  }
}

@import "../../theme/palette";
@import "../../theme/text";

/*BAR*/
.barWrapper {
  width: 100%;
  height: 100%;
}

/* HORIZONTAL STACKED BAR*/

.topBlock {
  display: flex;
  align-items: center;
}

.totalBlock {
  color: $grey1;
  display: flex;
  flex-direction: column;
  margin: 2rem 1rem 1rem 0;
}
.legendBlock {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 0 1rem;
}

.legendBlock_topRow {
  width: 100%;
  flex-wrap: wrap;
}
.legendBlock_botRow {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.totalNumber {
  font-family: $secondary_font1;
  font-size: 50px;
  font-weight: 700;
  margin-bottom: 1rem;
}

.totalText {
  @include body1;
  font-size: 16px;
  &::before {
    content: " ";
  }
}

.fullBar {
  width: 100%;
  background-color: $grey4;
  height: 21px;
  border-radius: 10px;
  margin: 0.2rem 0 0.5rem 0;
}

.startedAndDone {
  height: 100%;
  background-color: $secondary_blue2;
  border-radius: 10px;
}

.done {
  height: 100%;
  background-color: $primary_red1;
  border-radius: 10px;
}

.legendItem {
  display: flex;
  margin-bottom: 1rem;
  align-items: center;
}

.bar_legendColor {
  width: 15px;
  height: 15px;
  border-radius: 5px;
  margin-right: 0.5rem;
}

.doneColor {
  background-color: $primary_red1;
}

.startedColor {
  background-color: $secondary_blue2;
}

.unassignedColor {
  background-color: $grey4;
}

.legendText {
  @include body1;
  font-size: 16px;
  text-transform: capitalize;
}
.legendNumber {
  font-family: $secondary_font1;
  font-size: 24px;
  font-weight: 700;
  &::after {
    content: " ";
  }
}

@import "../../theme/text";
@import "../../theme/sharedDashboardContent";
@import "../../theme/sharedForm";

.wrapper {
  @include sharedDashboardContent_desc_padding;
}

.wrapperBottomBlock {
  @include sharedForm_formWrapper;
  padding-bottom: 1.7rem;
}

.formTitle {
  @include sharedForm_formTitle;
}

.noManagerText {
  @include body1;
  font-weight: 600;
  font-size: 18px;
  text-align: center;
  padding-top: 1.2rem;
}

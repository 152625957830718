@import '../../../theme/palette';
@import '../../../theme/text';
@import '../../../theme/button';
:global(#app) {
  .submitButton {
    @include submit_btn_override_semantic;
    margin: 1rem 0 0 0;
    padding: 16px 10px;
    max-width: 400px;
    &:hover {
      background-color: $secondary_blue2;
    }
  }
}

.title {
  font-family: $primary_font1;
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 0.5rem;
}

.description {
  @include body2;
}

.checkIcon {
  font-size: 49px;
  color: $green1;
}

@import "../../theme/palette";
@import "../../theme/text";
@import "../../theme/responsive";
@import "../../theme/sharedDashboardContent";
@import "../../theme/sharedForm";

.wrapper {
  height: 100%;
  padding-bottom: 1.2rem;
  @include sharedDashboardContent_desc_padding;
}

.headingContainer {
  margin: 1rem 0;
  .title {
    @include sharedDashboardContent_title;
  }
  .titleDesc {
    @include sharedDashboardContent_desc;
  }
}

.programManagersListWrapper {
  width: 95%;
}
.PMListHeaderBlock {
  margin: 1.2rem 0 1rem 0;

  @include sharedForm_formWrapper;
  padding-bottom: 1rem;
}

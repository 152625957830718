@import "../../theme/palette";
@import "../../theme/text";

.wrapper {
  height: 100%;
}

.titleBlock {
  padding: 0 0 0.5rem 0;
  display: flex;
  align-items: center;
}

.title {
  @include head2;
}

.listingBlock {
  height: 100%;
  ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  li {
    @include head1;
    padding-top: 1rem;
  }
}

.numeric {
  font-weight: 700;
  padding-left: 0;
}

@mixin table_sortIcon {
  cursor: pointer;
  position: absolute;
  right: 0;
}

@mixin table_headerTH {
  position: relative;
  top: 0;
  @include head2;
  font-size: 14px;
  background-color: $primary_red1;
  color: $other_white1;
  padding: 16px 16px;
  text-align: center;
  &:first-child {
    border-radius: 8px 0 0 0;
  }
  &:last-child {
    border-radius: 0 8px 0 0;
  }
}

@mixin table_headerTitleBlock {
  display: flex;
  justify-content: center;
  align-items: center;
}

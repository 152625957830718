@import "../../theme/palette";
@import "../../theme/text";
@import "../../theme/sharedForm";

.tbodyTD {
  @include body1;
  font-weight: 400;
  padding: 12px 16px;
  text-align: center;
}
.subdomain {
  position: relative;
  cursor: pointer;
  text-decoration: underline;
  &:hover {
    transition: all 0.15s;
    color: $secondary_blue2;
  }
}
.textClientName {
  cursor: pointer;
  color: black;
  font-weight: 700;
  &:hover {
    color: $secondary_blue2;
  }
}
.textSeeMore {
  position: relative;
  text-align: right;
  font-size: 14px;
  span {
    color: $grey9;
    text-decoration: underline;
  }
}

.ul {
  list-style: none;
  text-align: left;
  .li {
    position: relative;
    color: $other_black1;
    padding-left: 5px;
    &:hover {
      color: $secondary_blue2;
      &::before {
        background-color: $secondary_blue2;
      }
    }
    &::before {
      content: "";
      width: 5px;
      height: 5px;
      background-color: black;
      border-radius: 50%;
      position: absolute;
      left: -5px;
      top: 11px;
      transform: translateY(-50%);
    }
  }
}

@import "../../theme/palette";
@import "../../theme/text";
@import "../../theme/responsive";

@mixin normalText {
  font-family: $primary_font1;
}

@mixin shared_managerName_programTitle {
  font-size: 20px;
  font-family: $primary_font1;
  font-weight: 700;
  margin: 0 0 0.5em;
}

:global(#app) {
  .customedSegment {
    border: none;
    border-radius: none;
    box-shadow: none;
    display: flex;
    margin: 0.5rem 0;
  }
  .iconClose {
    position: absolute;
    top: 10px;
    right: 5px;
    z-index: 9;
    color: $secondary_red_MPB;
    font-size: 1.5rem;
    cursor: pointer;
  }
}

.wrapper {
  position: relative;
  border: 1px solid $grey4;
  margin: 0;
  background-color: $other_white1;
  border-radius: 5px;
  margin-bottom: 0.5rem;
  &:not(:first-child) {
    margin-top: 0.5rem;
  }

  .blueBlock {
    position: absolute;
    width: 5px;
    height: 100%;
    left: 0;
    top: 0;
    background-color: $secondary_blue2;
    z-index: 9;
  }
}

.normalText {
  @include normalText;
}

.boldtext {
  @include normalText;
  font-weight: 700;
}

.leftBlock {
  width: 50%;
  .managerName {
    @include shared_managerName_programTitle;
    color: $primary_blue1;
    display: flex;
    align-items: flex-start;
    span {
      margin-left: 5px;
    }
  }
}

.rightBlock {
  width: 50%;
  .programTitle {
    @include shared_managerName_programTitle;
  }
}

.programTagsBlock {
  display: flex;
  flex-wrap: wrap;
  .programTag {
    font-family: $primary_font1;
    font-size: 14px;
    margin: 0;
    border: 1px solid $grey4;
    border-radius: 15px;
    padding: 3px 10px;
    margin-top: 5px;
    &:hover {
      background-color: $secondary_blue3;
      border-color: $secondary_blue3;
      transition: all 0.4s;
      cursor: pointer;
    }
    &:not(:last-child) {
      margin-right: 5px;
    }
  }
}

.seeMoreText {
  position: absolute;
  color: $primary_blue1;
  font-family: $primary_font1;
  font-size: 14px;
  bottom: 2%;
  right: 1%;
  text-decoration: underline;
  font-style: italic;
  cursor: pointer;
}

@media screen and (max-width: $MD) {
  :global(#app) {
    .customedSegment {
      display: block;
    }
  }
  .leftBlock,
  .rightBlock {
    width: 100%;
  }
  .rightBlock {
    margin-top: 20px;
  }
}

@import "../../theme/palette";
@import "../../theme/text";

.tbodyTD {
  @include body1;
  font-weight: 400;
  padding: 12px 16px;
  text-align: center;
}

.choiceTD {
  text-align: center;
}

.selectedActiveRow {
  background-color: $secondary_blue3;
}

.App {
  overflow: hidden;
}

@font-face {
  font-family: "MulishRegular";
  src: local("Mulish"),
    url(./assets/font/Mulish/Mulish-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "MulishSemiBoldItalic";
  src: local("Mulish"),
    url(./assets/font/Mulish/Mulish-SemiBoldItalic.ttf) format("truetype");
}

@font-face {
  font-family: "MulishSemiBold";
  src: local("Mulish"),
    url(./assets/font/Mulish/Mulish-SemiBold.ttf) format("truetype");
}

@font-face {
  font-family: "MulishBold";
  src: local("Mulish"),
    url(./assets/font/Mulish/Mulish-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "MulishExtraLight";
  src: local("Mulish"),
    url(./assets/font/Mulish/Mulish-ExtraLight.ttf) format("truetype");
}

@font-face {
  font-family: "Tuffy";
  src: local("Tuffy"), url(./assets/font/Tuffy/Tuffy.otf);
}

@import "../../theme/palette";
@import "../../theme/text";

.wrapper {
  margin-top: 1.2rem;
  h3 {
    font-family: $primary_font1;
    font-size: 18px;
    font-weight: 700;
  }
  ul {
    list-style: none;
    li {
      margin-bottom: 1rem;
      padding-left: 5px;
      font-family: $primary_font1;
      font-weight: 400;
      font-size: 18px;
      &::before {
        padding-right: 5px;
        content: "-";
        font-weight: bold;
      }
    }
  }
}

@import "../../theme/palette";
@import "../../theme/text";
:global(#app) {
  .loadingIcon {
    font-size: 2.5rem;
    color: $grey5;
  }
}
.wrapper {
  position: absolute;
  left: 0;
  top: 110%;
  min-width: 14rem;
  min-height: 5rem;
  background-color: $other_white1;
  border-radius: 5px;
  z-index: 999;
  padding: 5px 15px 10px 5px;
  box-shadow: 0px 1px 4px 1px rgba(99, 99, 99, 0.2);
}
.resultItem {
  font-family: $primary_font5;
  font-size: 1.1rem;
  color: black;
  font-weight: 600;
  margin: 0.5rem 0;
  position: relative;
  &:after {
    transition: all 0.4s;
    content: "";
    width: 0;
    height: 1px;
    background-color: $secondary_blue2;
    position: absolute;
    bottom: 0;
    left: 0;
  }
  &:hover {
    color: $secondary_blue2;
    &:after {
      width: 100%;
    }
  }
}

@import "./palette";
@import "./text";

$form_save_cancel_btn_width: 12rem;

@mixin primary_btn {
  border-radius: 8px;
  background-color: $primary_blue1;
  color: $other_white1;
  border: transparent;
  transition: all 0.3s;
  &:hover {
    background-color: $secondary_blue2;
  }
}

@mixin secondary_btn {
  border: 1px solid #000000;
  color: $grey1;
  border-radius: 8px;
  transition: all 0.3s;
  background-color: $other_white1;
  &:hover {
    border-color: $secondary_blue2;
    background-color: $secondary_blue2;
    color: $other_white1;
  }
}

@mixin tertiary_btn {
  border: 1px solid $secondary_red_MPB;
  color: $secondary_red_MPB;
  border-radius: 8px;
  &:hover {
    background-color: $secondary_red_MPB;
    color: white;
  }
}

@mixin primary_disabled_btn {
  border: transparent;
  color: $other_black1;
  background-color: $grey4;
  border-radius: 8px;
  &:hover {
    background-color: $grey4;
  }
}

@mixin submit_btn_override_semantic {
  color: #ffffff;
  font-family: $primary_font5;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  letter-spacing: 1px;
  background-color: $primary_blue1;
  border-radius: 8px;
  width: 100%;
}

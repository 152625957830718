@import "../../theme/palette";
@import "../../theme/text";
@import "../../theme/button";

:global(#app) {
    .dimmerCus {
        background-color: rgba(0, 0, 0, 0.1);
    }

    .closeIcon {
        top: -0.125rem;
        right: 0.25rem;
        font-size: 2rem;
    }

    .headerWrapper {
        padding: 0;
        overflow: hidden;

        .title {
            text-align: center;
            padding: 2rem 1.5rem;
            color: $other_white1;
            background-color: $primary_blue1;
            @include head2;
            font-size: 24px;
        }
    }

    .contentWrapper {
        padding: 1rem 1.25rem;
        min-height: 150px;
    }

    .actionWrapper {
        padding-bottom: 2rem;
        background-color: $white;
        border-top: none;
    }
}
@import "../../theme/sharedDashboardContent";
@import "../../theme/sharedForm";

.wrapper {
  @include sharedDashboardContent_desc_padding;
}

.programDashboardText {
  @include sharedForm_formTitle;
}

:global(#app) {
  .dropDown {
    border: 1px solid $grey4;
    border-radius: 5px;
  }
}

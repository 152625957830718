@import "../../theme/palette";

#paginationId {
  .menu {
    min-height: 1.2rem;
  }
  .item {
    padding: 0.3rem 0;
    outline: none;
    justify-content: center;
    min-width: 2rem;
  }
  .active {
    padding: 0;
    background-color: $blue6;
    color: $other_white1;
  }
}

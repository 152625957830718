@import "../../theme/palette";
@import "../../theme/text";
@import "../../theme/responsive";
@import "../../theme/sharedDashboardContent";

:global(#app) {
  .p0 {
    padding: 0;
  }
  .p1 {
    padding: 0.5rem 1rem;
  }
}

.wrapper {
  width: 100%;
  height: 100%;
  background-color: $wildSand;
}

.statisticalBlock {
  margin-top: 1rem;
  padding: 1.4rem 1.2rem 1.2rem 1rem;
}

.statisticalItemBlock {
  border-radius: 10px;
  background-color: $other_white1;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
  padding-bottom: 0.5rem;
  padding: 0.5em;
}

.highlighNumber {
  @include statisticNumber2;
  margin: 0;
  text-align: center;
  padding: 20px 0;
}

.statisticalDesc {
  @include head2;
  color: $other_black1;
  padding-left: 1rem;
  padding-top: 0.5rem;
  margin-bottom: 0.5rem;
}


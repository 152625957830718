@import '../../theme/palette';
@import '../../theme/text';
@import '../../theme/button';

:global(#app) {
  .btn {
    width: 25%;
    max-width: 12rem;
    outline: none;
    cursor: pointer;
    padding: 15px 0;
    border: none;
  }
  .cancelBtn {
    @include overline;
    @include secondary_btn;
  }
  .agreeBtn {
    @include tertiary_btn;
    background-color: $other_white1;
    margin-left: 1rem;
  }

  .closeBtn {
    @include primary_btn;
  }

  .checkIcon {
    color: $green1;
    font-size: 4rem;
  }
}

.alignCenter {
  text-align: center;
}

.title {
  text-align: center;
  padding: 2rem 1.5rem;
  color: $other_white1;
  background-color: $secondary_red_MPB;
  @include head2;
  font-size: 24px;
}

.contentQuestion {
  @include head2;
  line-height: 35px;
  padding-top: 0;
  font-size: 18px;
}

.primaryText {
  color: $secondary_blue2;
  font-size: 18px;
}

.buttonsWrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;
  padding: 0 1rem 0 1rem;
  span {
    @include head2;
  }
}

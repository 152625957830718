@import "./palette";
@import "./text";
@import "./button";
@import "./responsive";

$labelWidth: 25%;

@mixin sharedForm_formWrapper {
  background-color: $other_white1;
  border-radius: 8px;
  padding: 1rem 1.6rem 4rem 1.6rem;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
}

@mixin sharedForm_formField {
  border-bottom: 1px solid $grey4;
  padding-bottom: 5.5px;
  margin-top: 1.5rem;
  display: flex;
  @media screen and (max-width: $MD) {
    display: block;
  }
}

@mixin sharedForm_formTitle {
  @include head2;
  color: $red1;
  margin-bottom: 0;
}

@mixin sharedForm_label {
  @include body1;
  font-weight: 600;
}

@mixin sharedForm_sup {
  color: $red1;
  font-size: 18px;
  font-weight: bold;
  top: 0;
}
@mixin sharedForm_dangerMessage {
  @include head1;
  color: $secondary_red_MPB;
  margin: 0;
}

@mixin sharedForm_inputField {
  font-family: $primary_font1;
  border: none;
  outline: none;
  width: 70%;
  color: $other_black1;
  &::placeholder {
    @include body1;
    letter-spacing: 2%;
  }
  @media screen and (max-width: $MD) {
    width: 100%;
  }
}

@mixin sharedForm_rightGroup {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

/*BUTTON*/
@mixin btn_text_style {
  text-transform: uppercase;
}
@mixin sharedForm_btnCancel {
  width: 10rem;
  height: 5rem;
  @include button;
  font-size: 18px;
  cursor: pointer;
  outline: none;
  @include secondary_btn;
  @include btn_text_style;
  font-weight: 700;
}
@mixin sharedForm_btnSave {
  width: 10rem;
  height: 5rem;
  @include button;
  font-size: 18px;
  cursor: pointer;
  outline: none;
  @include primary_disabled_btn;
  @include btn_text_style;
  font-weight: 700;
  @media screen and (max-width: $MD) {
    margin-bottom: 20px;
  }
}

@mixin sharedForm_activeSaveBtn {
  @include primary_btn;
}

@mixin sharedForm_editBtn {
  position: absolute;
  right: -5px;
  top: 0;
  background: $primary_blue1;
  padding-left: 1rem;
  padding-right: 1rem;
  height: 3rem;
  color: $other_white1;
  font-size: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 5px;
  &:hover {
    background-color: $secondary_blue2;
  }
}

@mixin sharedForm_pencilIcon {
  @include Responsive(SM) {
    margin: 0;
  }
}

@mixin sharedForm_editText {
  @include Responsive(SM) {
    display: none;
  }
}

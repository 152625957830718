@import "../../../theme/palette";
@import "../../../theme/text";

:global(#app) {
  .btn {
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    background-color: $other_white1;
    border: 1px solid $other_white1;
    transition: all 0.4s;

    &:hover {
      color: $primary_red1;
      border-color: $primary_red1;
      &:nth-child(2) {
        color: $secondary_blue2;
        border-color: $secondary_blue2;
      }
    }
    span {
      @include button;
      font-size: 14px;
    }
  }

  .invisible {
    opacity: 0;
  }
}

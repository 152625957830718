@import "../../theme/palette";
@import "../../theme/text";
@import "../../theme/sharedDashboardContent";
@import "../../theme/sharedForm";

:global(#app) {
  .customedSegment {
    @include sharedForm_formWrapper;
    padding-bottom: 2rem;
  }
  .plusIcon {
    color: $blue4;
    margin-top: 1.5rem;
    cursor: pointer;
  }
  .iconRemove {
    color: $secondary_red_MPB;
    cursor: pointer;
  }

  .editBtn {
    @include sharedForm_editBtn;
  }
}

.title {
  @include sharedForm_formTitle;
}

.firstContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 2rem;
  span {
    @include overline;
    font-size: 18px;
    &:first-child {
      margin-bottom: 0.5rem;
    }
  }
}

.mainContent {
  display: flex;
}

.textErrorMessage {
  @include sharedForm_dangerMessage;
}

.textSuccessMessage {
  color: $primary_blue1;
  font-family: $primary_font1;
  font-weight: 400;
  font-size: 18px;
  margin: 0;
}

/*Form*/
.form {
  width: 88%;
}
.formField {
  @include sharedForm_formField;
}

.labelWrapper {
  @include sharedForm_label;
  width: 30%;
}

.inputField {
  @include sharedForm_inputField;
}

/*Right group*/
.rightGroup {
  @include sharedForm_rightGroup;
  justify-content: flex-start;
  align-items: center;
  width: 30%;
  padding-top: 2rem;
}

.btnCancel {
  @include sharedForm_btnCancel;
}

.btnSave {
  @include sharedForm_btnSave;
  margin-bottom: 1rem;
  transition: all 0.5s;
  cursor: default;
}

.btnActive {
  @include sharedForm_activeSaveBtn;
  cursor: pointer;
}

.disable {
  @include primary_disabled_btn;
}

.totalPMBlock {
  margin-top: 10%;
  padding: 1em;
  border: 1px solid $grey4;
  width: 160px;
  height: 170px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.bigNumber {
  font-family: $primary_font1;
  font-size: 36px;
  color: $secondary_red_MPB;
}

.text1 {
  margin: 0;
  padding-top: 1rem;
  @include overline;
  font-size: 18px;
  line-height: 18px;
  text-align: center;
}

.searchWrapper {
  padding-top: 1rem;
  display: flex;
  align-items: center;
}

.selectedCoachWrapper {
  padding-left: 1.1rem;
  ul {
    list-style: none;
    li {
      margin-top: 1rem;
      width: 90%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      &::before {
        content: "";
        height: 7px;
        width: 7px;
        background-color: $other_black1;
        border-radius: 50%;
        position: absolute;
        right: 103%;
        top: 30%;
      }
      span {
        @include body1;
        font-weight: 600;
      }
    }
  }
}

.listCoachesText {
  @include body1;
  font-weight: 600;
  padding: 1.2rem 0;
}
/* ${  fetching || (updateProgramResult.fetching && styles.disable)}  */

@import "../../theme/palette";
@import "../../theme/text";
@import "../../theme/sharedForm";
@import "../../theme/sharedDashboardContent";
@import "../../theme/table";

:global(#app) {
  .sortIcon {
    @include table_sortIcon;
  }
}

.wrapper {
  margin-top: 1rem;
  width: 100%;
  @include sharedForm_formWrapper;
  background-color: $wildSand;
  box-shadow: none;
  @include sharedDashboardContent_desc_padding;
  padding-bottom: 2rem;
}

.headingContainer {
  margin: 1rem 0;
  .title {
    @include sharedDashboardContent_title;
  }
  .titleDesc {
    @include sharedDashboardContent_desc;
  }
}

.pagination {
  text-align: right;
}

.tableWrapper {
  overflow-y: auto;
  margin-top: 0.8rem;
  box-shadow: 2px 0px 9px rgba(40, 40, 40, 0.25);
  border-radius: 8px;
}

.coachTable {
  width: 100%;
  text-align: center;
  border-collapse: collapse !important;
  border-radius: 8px 8px 0 0;
  .fixedHeader {
    .headerTR {
      height: 3.5rem;
    }
  }
}

.headerTH {
  @include table_headerTH;
}

.headerTitleBlock {
  @include table_headerTitleBlock;
}

.no_TH {
  width: 5%;
}

.name_TH {
  width: 35%;
}

.accountStatus_TH {
  width: 20%;
}

.certification_TH {
  width: 25%;
}

.programNumber_TH {
  width: 15%;
}

.searchInputAndPaginationWrapper {
  @include sharedSearchInputAndPaginationWrapper;
}

@import "./palette";
@import "./text";
@import "./sharedForm";

.topLevelText {
  text-transform: uppercase;
}

.secondLevelText {
  text-transform: capitalize;
}

.darkRowBackground {
  background-color: $grey10;
}

.tableListRow {
  &:nth-child(odd) {
    background-color: $other_white1;
  }
  &:nth-child(even) {
    background-color: $grey10;
  }
}

.buttonText {
  font-family: $primary_font5;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
}

.textDangerFormMessage {
  @include sharedForm_dangerMessage;
}

.textInfoFormMessage {
  @include sharedForm_dangerMessage;
  color: $secondary_blue2;
}

/*CALENDAR*/
.react-calendar {
  border-radius: 8px;
  box-shadow: 5px 5px 8px 7px rgba(0, 0, 0, 0.2);
}

.react-calendar__navigation__label__labelText {
  text-transform: capitalize;
  @include head1;
}

.react-calendar__month-view__weekdays__weekday {
  @include head2;
  font-size: 14px;
  text-transform: capitalize;
  abbr {
    text-decoration: none !important;
  }
}

.react-calendar__navigation {
  margin-bottom: 0 !important;
}

.react-calendar__month-view__weekdays {
  padding: 0.4rem 0.5rem !important;
}
.react-calendar__month-view__days {
  padding: 0.2rem 0.5rem !important;
}

.react-calendar__tile {
  position: relative;
  border-radius: 50%;
  &:hover {
    color: $other_white1;
    background-color: $secondary_blue2 !important;
    border-radius: 50%;
  }
}
.react-calendar__tile--now {
  background-color: transparent !important;
  border: 1px solid $primary_blue1 !important;
  border-radius: 50%;
  abbr {
    z-index: 99 !important;
  }
}

.react-calendar__tile--active {
  background-color: $primary_blue1 !important;
  border-radius: 50%;
}

@import "../../theme/palette";
@import "../../theme/text";
@import "../../theme/responsive";
@import "../../theme/sharedForm";
@import "../../theme/button";

.formWrapper {
  @include sharedForm_formWrapper;
  margin-top: 1rem;
  position: relative;
}

.container {
  display: flex;
  width: 100%;
}

.formCreateProgram {
  width: 75%;
}

.formTitle {
  @include sharedForm_formTitle;
}

.formField {
  @include sharedForm_formField;
  .inputWidth {
    width: 92%;
  }
}

.labelWrapper {
  @include sharedForm_label;
  width: 30%;
}

.offerConfigLabelWrapper {
  @include sharedForm_label;
}

.sup {
  @include sharedForm_sup;
}

.inputField {
  @include sharedForm_inputField;
}

.inputSearchWrapper {
  width: 70%;
  position: relative;
  display: flex;
  justify-content: space-between;
}

.rightGroup {
  @include sharedForm_rightGroup;
  justify-content: flex-start;
  align-items: center;
  width: 30%;
  padding-top: 2rem;
}
.center {
  padding-top: 2rem;
}

.btnCancel {
  @include sharedForm_btnCancel;
}

.btnSave {
  @include sharedForm_btnSave;
  margin-bottom: 1rem;
}

.btnActive {
  @include sharedForm_activeSaveBtn;
}

.disable {
  @include primary_disabled_btn;
}

.textErrorMessage {
  @include sharedForm_dangerMessage;
}

.calendarInputBlock {
  position: relative;
}

.programInfoText {
  color: $secondary_red_MPB;
  @include head2;
}

.calendar {
  position: absolute;
  top: 100%;
  left: 0;
  max-width: 900px;
  z-index: 9;
}

.totalPMBlock {
  margin-top: 10%;
  padding: 1em;
  border: 1px solid $grey4;
  width: 160px;
  height: 170px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.text1 {
  margin: 0;
  padding-top: 1rem;
  @include overline;
  font-size: 18px;
  line-height: 18px;
  text-align: center;
}

.bigNumber {
  font-family: $primary_font1;
  font-size: 36px;
  color: $secondary_red_MPB;
}

.editText {
  @include sharedForm_editText;
}
/*******ConfigBlock*********/

.configTitle {
  font-family: $primary_font1;

  font-weight: 600;
}

.configInput {
  text-align: center;
  font-family: $primary_font1;
  font-weight: 400;
}

.inputAlert {
  color: $secondary_red_MPB;
}

:global(#app) {
  .customInput {
    border: none;
    padding: 0;
    height: auto;
    min-height: auto;
    min-width: auto;
    width: 2.7rem;
    input {
      padding: 0;
    }
    i {
      padding: 0;
    }
  }

  .editBtn {
    @include sharedForm_editBtn;
  }

  .searchIcon {
    cursor: pointer;
    color: $grey7;
  }

  .highLightIcon {
    color: $other_black1;
  }

  .dropdown {
    background-color: $other_white1;
    color: $other_black1;
    font-family: $primary_font1;
    font-weight: 400;
  }

  .pencilIcon {
    @include sharedForm_pencilIcon;
  }
}

.inputText {
  font-family: $primary_font1;
  font-weight: 400;
}

/* HELPER*/
.glass_border {
  border: transparent;
}

@media screen and (max-width: $MD) {
  .container {
    display: block;
  }
  .inputSearchWrapper,
  .formCreateProgram,
  .rightGroup {
    width: 100%;
  }
}

@import '../../theme/palette';
@import '../../theme/text';
@import '../../theme/sharedForm';
@import '../../theme/button';
@import '../../theme/responsive';

:global(#app) {
  .btnCancel {
    margin-top: 5px;
    width: 12rem;
    height: 5rem;
    @include secondary_btn;
  }
  .btnSave {
    width: 12rem;
    height: 5rem;
    @include primary_disabled_btn;
  }

  .activeSave {
    @include primary_btn;
  }
}

.formWrapper {
  margin-top: 1.2rem;
  position: relative;
  @include sharedForm_formWrapper;
  padding-bottom: 3.5rem;
}

.formTitle {
  @include sharedForm_formTitle;
}

.formContainer {
  display: flex;
}

.formCreateClient {
  width: 70%;
  .formField {
    @include sharedForm_formField;

    .labelWrapper {
      min-width: 170px;
    }

    .label {
      @include sharedForm_label;
    }
    .sup {
      @include sharedForm_sup;
    }

    .timeInputWrapper {
      display: flex;
    }
    .timeInputBlock {
      padding: 0 5px;
      justify-content: space-between;
    }
    .inputField {
      @include sharedForm_inputField;
    }
    .timeInputField {
      width: 35px;
      text-align: center;
    }
    .w40 {
      width: 40%;
      padding-right: 5px;
    }

    .mpbDomain {
      font-family: $primary_font1;
      font-weight: 600;
    }
    .domainActive {
      color: $lightBlue;
      text-decoration: underline;
    }
  }
  .specialLabelWrapper {
    min-width: 240px !important;
  }
}

.rightGroup {
  @include sharedForm_rightGroup;
  width: 30%;
  padding-top: 3rem;
  padding-left: 20px;
}

:global(#app) {
  .editBtn {
    @include sharedForm_editBtn;
  }

  .dropWrapper {
    display: flex;
    flex-wrap: wrap;
    .customInput {
      border: none;
      padding: 0;
      height: auto;
      min-height: auto;
      min-width: auto;
      display: flex;
      align-items: center;
      margin-right: 1.2rem;
      input {
        padding: 0;
      }
      i {
        padding: 0;
      }
    }
  }
  .date {
    width: 7rem;
  }
  .hour {
    width: 4rem;
  }

  .disable {
    @include primary_disabled_btn;
  }

  .pencilIcon {
    @include sharedForm_pencilIcon;
  }
}

.textErrorMessage {
  @include sharedForm_dangerMessage;
}

.textFromDate {
  @include StronglyEmphasize;
  margin-right: 0.8rem;
}

.textToDate {
  @include StronglyEmphasize;
  margin-right: 10px;
}
.textFromToHour {
  @include StronglyEmphasize;
  margin: 0 0;
}

.scheduleWrapper {
  width: 100%;
  height: 98%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  border: 1px solid #d6d6d6;
  padding: 1rem 5px;
  .textCoachingOpening {
    text-align: center;
    @include overline;
    font-size: 1.2rem;
    margin: 0;
  }
  .dateSquaresContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-top: 8px;

    .dateSquare {
      margin-top: 0.2rem;
      text-align: center;
      @include body1;
      font-weight: 800;
      line-height: 22px;
      background-color: $blue3;
      padding: 1px;
      border: 1px solid $lightBlue;
      border-radius: 3px;
      width: 25px;
      height: 25px;
      &:not(:first-child) {
        margin-left: 5px;
      }
    }
    .dateOff {
      background-color: $other_white1;
    }
  }
  .textWorkingHour {
    text-align: center;
    @include body1;
    font-weight: 600;
    margin-top: 8px;
  }
  .numberHour {
    color: $lightBlue;
  }
}

@media screen and (max-width: $MD) {
  .formContainer {
    display: block;
  }
  .formCreateClient,
  .rightGroup {
    width: 100%;
    padding-left: 0;
  }
}

.editText {
  @include sharedForm_editText;
}

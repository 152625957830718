@import "../../theme/text";
@import "../../theme/palette";
@import "../../theme/button";
:global(#app) {
  .submitButton {
    @include submit_btn_override_semantic;
    text-transform: uppercase;
    margin: 0;
    margin-top: 4.22rem;
    &:hover {
      background-color: $secondary_blue2;
    }
  }
  .formBlock {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  input:-webkit-autofill {
    border-color: black !important;
  }
  .formField {
    width: 100%;
    margin: 7px 0;

    .inputField {
      border: none;
      padding: 0;
      padding-bottom: 1rem;
      font-size: 18px;
      font-family: $primary_font5;
      border-bottom: 1px solid #e2e1e1;
      border-radius: 0;
      &:focus {
        border-bottom: 1.5px solid black;
      }
    }
  }
}

.forgetPasswordText {
  font-family: $primary_font5;
  color: #8f8f8f;
  font-weight: normal;
  font-size: 15px;
  cursor: pointer;
  &:hover {
    color: black;
    font-weight: 600;
    text-decoration: underline;
  }
}

.errorMessage {
  margin-top: 0.7rem;
  margin-left: 0.2rem;
  border-radius: 0.28571429rem;
  position: relative;
  color: #ff0000;
  width: 100%;
  text-align: center;
  padding: 3px 0;
  visibility: hidden;
  @include button;
}
.visible {
  visibility: visible;
}

@import '../../theme/palette';
@import '../../theme/text';
@import '../../theme/responsive';
@import '../../theme/button';

:global(#app) {
  .submitButton {
    @include submit_btn_override_semantic;
    margin: 0;
    text-transform: uppercase;
    @include Responsive(LG) {
      max-width: 400px;
    }
    &:hover {
      background-color: $secondary_blue2;
    }
  }
  input:-webkit-autofill {
    border-color: #fa6767 !important;
  }
  .formBlock {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .formField {
    width: 100%;
    margin-top: 10%;
    @include Responsive(LG) {
      max-width: 400px;
    }

    .inputField {
      border: none;
      padding: 0;
      padding-bottom: 1rem;
      font-size: 18px;
      font-family: $primary_font5;
      border-bottom: 1px solid #e2e1e1;
      border-radius: 0;
      &:focus {
        border-bottom: 1.5px solid black;
      }
    }
  }
}

.title {
  @include body1;
  font-weight: 700;
  margin: 3px 0 !important;
  letter-spacing: 0.75px;
}
.description {
  @include body2;
  margin: 3px 0 !important;
  max-width: 279px;
}

.forgetPasswordText {
  font-family: $primary_font5;
  color: #8f8f8f;
  font-weight: normal;
  font-size: 15px;
  cursor: pointer;
  &:hover {
    color: black;
    text-decoration: underline;
    font-weight: 600;
  }
}

.errorMessage {
  margin-top: 0.7rem;
  margin-bottom: 0;
  margin-left: 0.2rem;
  border-radius: 0.28571429rem;
  position: relative;
  color: #ff0000;
  width: 100%;
  text-align: center;
  padding: 3px 0;
  @include button;
  &:last-child {
    margin-top: 0;
  }
}

@import '../../theme/palette';
@import '../../theme/text';
@import '../../theme/responsive';
@import '../../theme/sharedForm';
@import '../../theme/button';
:global(#app) {
  .uploadIcon {
    font-size: 4rem;
    color: $grey3;
  }

  .excelColor {
    color: #107c41;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0.3rem;
  }

  .errorColor {
    color: $secondary_red_MPB;
  }

  .customedSegment {
    border: none;
    box-shadow: none;
    margin-top: 0;
  }

  .btnCancel {
    width: 10.5rem;
    height: 3.5rem;
    @include button;
    font-size: 18px;
    cursor: pointer;
    outline: none;
    @include secondary_btn;
    @include btn_text_style;
    font-weight: 700;
  }
  .btnUpload {
    width: 10rem;
    height: 3.5rem;
    @include button;
    font-size: 18px;
    cursor: pointer;
    outline: none;
    @include primary_disabled_btn;
    @include btn_text_style;
    font-weight: 700;
    margin-right: 1.5rem;
    opacity: 100 !important;
  }

  .btnActive {
    @include sharedForm_activeSaveBtn;
  }
}
.wrapper {
  position: relative;
  @include sharedForm_formWrapper;
  padding-bottom: 0;
}

.formTitle {
  @include sharedForm_formTitle;
}

.formDesc {
  @include subtitle;
  color: $other_black1;
  margin-bottom: 0;
}

.titleDescBold {
  font-weight: bold;
}

.uploadWrapper {
  margin-top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 12rem;
  border: 3px dashed $grey4;
  position: relative;
  &:focus {
    outline: none;
  }

  .uploadDesc {
    @include head2;
    font-size: 24px;
    color: $grey2;
    text-align: center;
    word-break: break-all;
  }
  .textClickable {
    color: $secondary_blue2;
    position: relative;
    cursor: pointer;
    font-size: 24px;
    &::after {
      content: '';
      height: 1.5px;
      width: 100%;
      background-color: $secondary_blue2;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
  .textClickableUploadSuccess {
    color: $secondary_blue2;
    position: relative;
    cursor: pointer;
    font-size: 16px;
    &::after {
      content: '';
      height: 1.5px;
      width: 100%;
      background-color: $secondary_blue2;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }

  .fileName {
    @include head2;
    color: $secondary_blue2;
    font-size: 24px;
  }
}

.layer {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: $grey4;
  opacity: 0.4;
}

.btnGroup {
  text-align: right;
  margin-top: 1.5rem;
}

.uploadSuccessTextHead {
  @include StronglyEmphasize;
  margin: 0;
}

.uploadSuccessTextDesc {
  @include body2;
}

.messageText {
  margin-bottom: 0;
}

@import "./palette";
@import "./text";

@mixin sharedDashboardContent_title {
  margin: 0;
  @include head3;
  color: $primary_blue1;
  line-height: 32px;
}

@mixin sharedDashboardContent_desc {
  @include body3;
  line-height: 18px;
  margin-top: 0.9rem;
}

@mixin sharedDashboardContent_desc_padding{
  padding: 0 1rem;
}

@mixin sharedDashboardContent_navlink_blue_bottom{
  content: "";
  height: 5px;
  width: 0%;
  background-color: $secondary_blue2;
  position: absolute;
  bottom: 0;
  left: 0;
  border-radius: 8px;
}

@mixin sharedSearchInputAndPaginationWrapper {
  display:flex;
  justify-content: space-between;
}

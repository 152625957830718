@import "../../theme/palette";
@import "../../theme/text";

.wrapper{
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.chartWrapper {
  width: 100%;
  background-color: $other_white1;
  border-radius: 10px;
  max-height: 388px;
  padding: 15px 20px 30px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
}

.chartTitle {
  @include head2;
  color: $other_black1;
  margin-bottom: 0;
}

.chartTitleHighlight {
  color: $primary_blue1;
}

.chartContainer {
  width: 100%;
  height: auto;
}

@import "../../theme/palette";
@import "../../theme/text";
@import "../../theme/sharedDashboardContent";

.wrapper {
  height: 100%;
  background-color: $other_white1;
  padding-bottom: 2rem;
}
.navBlock {
  width: 100%;
  background-color: $wildSand;
  height: 60px;
  display: flex;
  align-items: flex-end;
  @include sharedDashboardContent_desc_padding;
}
.navItem {
  @include overline;
  font-size: 18px;
  padding: 1rem 1.3rem;
  position: relative;
  color: $grey2;
  margin-right: 2px;
  &::after {
    transition: all 0.2s;
    background-color: $other_black1;
    @include sharedDashboardContent_navlink_blue_bottom;
  }
  &:hover {
    color: $other_black1;
    &::after {
      background-color: $secondary_blue2;
      width: 100%;
    }
  }
  &:not(:last-child) {
    margin-right: 0.5rem;
  }
}
.active {
  color: $other_black1;
  &::after {
    @include sharedDashboardContent_navlink_blue_bottom;
    width: 100%;
  }
}

@import "../../theme/palette";
@import "../../theme/text";
@import "../../theme/sharedForm";

.tableListRow {
  tr {
    &:nth-child(odd) {
      background-color: $other_white1;
    }
    &:nth-child(even) {
      background-color: $grey4;
    }
  }
}

.tbodyTD {
  @include body1;
  font-weight: 400;
  padding: 12px 16px;
  text-align: center;
  ul {
    list-style: none;
    li {
      padding: 2px 0;
    }
  }
}

.coachName {
  color: $other_black1;
  font-weight: 600;
}

.boldText {
  font-weight: 600;
}

$XS: 320px; // < iphone 5
$SM: 576px; //mobile
$MD: 768px; //iPads,tablets
$LG: 991px; //small monitors
$XL: 1200px; //wide screen monitors

@mixin Responsive($canvas) {
  @if $canvas == XS {
    @media only screen and (max-width: $XS) {
      @content;
    }
  } @else if $canvas == SM {
    @media only screen and (max-width: $SM) {
      @content;
    }
  } @else if $canvas == MD {
    @media only screen and (max-width: $MD) {
      @content;
    }
  } @else if $canvas == LG {
    @media only screen and (max-width: $LG) {
      @content;
    }
  } @else {
    @media only screen and (max-width: $XL) {
      @content;
    }
  }
}

@import "../../theme/palette";
@import "../../theme/text";
@import "../../theme/sharedForm";

:global(#app) {
  .iconReload {
    color: $secondary_blue2;
    font-size: 1.3rem;
    padding-top: 3px;
    cursor: pointer;
  }
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include sharedForm_formWrapper;
  padding-bottom: 1.7rem;
  margin-bottom: 1rem;
}

.title {
  @include sharedForm_formTitle;
}

.rightBlock {
  // width: 100%;
  padding-left: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.searchNDropdownBlock {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

:global(#app) {
  .dropDown {
    margin-left: 1rem;
    min-width: 255px;
  }
}

@media screen and (max-width: $MD) {
  .wrapper {
    display: block;
    .rightBlock {
      padding-left: 0;
      margin-top: 10px;
    }
  }
}

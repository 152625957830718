@import '../../theme/palette';
@import '../../theme/text';
@import '../../theme/sharedForm';
@import '../../theme/responsive';
@import '../../theme/button';

:global(#app) {
  .editBtn {
    @include sharedForm_editBtn;
  }
  .addIcon {
    font-size: 5.5rem;
    color: $blue4;
    margin-top: 2rem;
    height: 1rem;
    cursor: pointer;
  }
  .searchIcon {
    cursor: pointer;
    color: $grey7;
  }

  .pencilIcon {
    @include sharedForm_pencilIcon;
  }

  .btnSave {
    @include primary_disabled_btn;
    width: 12rem;
    height: 5rem;
  }

  .btnCancel {
    @include secondary_btn;
    width: 12rem;
    height: 5rem;
    @media screen and (max-width: 430px) {
      margin-top: 0.5rem;
    }
  }

  .activeSave {
    @include primary_btn;
  }
}

.formWrapper {
  @include sharedForm_formWrapper;
  margin-top: 0.8rem;
  position: relative;
}

.emptyFormWrapper {
  text-align: center;
  width: 65%;
  margin: 0 auto;
  margin-top: 2rem;
  .textEmptyForm {
    font-family: $primary_font3;
    font-size: 18px;
    color: $grey1;
  }
}

.formContainer {
  display: flex;
}

.formTitle {
  @include sharedForm_formTitle;
  margin: 0;
}

.formCreateDirector {
  width: 70%;
  .formRow {
    position: relative;
  }
  .formField {
    @include sharedForm_formField;
    align-items: center;
    .w42 {
      width: 42%;
    }
    .w66 {
      width: 66%;
    }
    .labelWrapper {
      width: $labelWidth;
      .label {
        @include body1;
        font-weight: 600;
      }
    }
  }

  .positioningInput {
    position: absolute;
    bottom: 0;
    right: 0;
  }

  .sup {
    color: $red1;
    font-size: 18px;
    font-weight: bold;
    top: 0;
  }
  .inputField {
    border: none;
    outline: none;
    width: 70%;
    &::placeholder {
      @include body1;
      letter-spacing: 2%;
    }
  }
}

.inputSearchWrapper {
  width: 70%;
  position: relative;
}

.rightGroup {
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  @include Responsive(MD) {
    flex-direction: row;
    justify-content: flex-end;
  }
  @media screen and (max-width: 430px) {
    flex-direction: column;
    align-items: flex-end;
  }
}

.textResMessage {
  @include sharedForm_dangerMessage;
}

.editText {
  @include sharedForm_editText;
}

@media screen and (max-width: $MD) {
  .formContainer {
    display: block;
  }
  .formCreateClient,
  .rightGroup {
    width: 100%;
    padding-left: 0;
  }
  .rightGroup {
    margin-top: 10px;
  }

  .formCreateDirector {
    width: 100%;
    .formField .w66 {
      width: 100%;
    }
    .positioningInput {
      position: relative;
    }
  }
}

@media screen and (max-width: $MD) {
  .inputSearchWrapper {
    width: 100%;
  }
}

@import "../../theme/palette";
@import "../../theme/text";
@import "../../theme/sharedForm";
@import "../../theme/sharedDashboardContent";
@import "../../theme/table";

:global(#app) {
  .sortIcon {
    @include table_sortIcon;
  }
}

.wrapper {
  margin-top: 1rem;
  width: 100%;
  @include sharedForm_formWrapper;
  background-color: $wildSand;
  box-shadow: none;
  @include sharedDashboardContent_desc_padding;
  padding-bottom: 2rem;
}

.headingContainer {
  margin: 1rem 0;
  .title {
    @include sharedDashboardContent_title;
  }
  .titleDesc {
    @include sharedDashboardContent_desc;
  }
}

.pagination {
  text-align: right;
}

.tableWrapper {
  overflow-y: auto;
  margin-top: 0.8rem;
  box-shadow: 2px 0px 9px rgba(40, 40, 40, 0.25);
  border-radius: 8px;

  .programTable {
    width: 100%;
    text-align: center;
    border-collapse: collapse;
    border-radius: 8px 8px 0 0;
    .fixedHeader {
      .headerTR {
        height: 3.5rem;
      }
    }
  }
}

.headerTH {
  @include table_headerTH;
}

.programName_TH{
  width: 22%;
}
.client_TH {
  width: 21%;
}

.courseOverTotal_TH {
  width: 10%;
}

.programManager_TH {
  width: 10%;
}

.coach_TH{
  width: 10%;
}

.coachee_TH {
  width: 10%;
}

.closeDate_TH{
  width: 12%;
}

.headerTitleBlock {
  @include table_headerTitleBlock;
}

.secondaryBackground {
  background-color: $grey10;
}

.searchInputAndPaginationWrapper {
  @include sharedSearchInputAndPaginationWrapper;
}

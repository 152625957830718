@import "../../theme/palette";
@import "../../theme/text";
.searchWrapper {
  width: max-content;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: 31px;
  background-color: $other_white1;
  padding-right: 0.2rem;
  .searchBar {
    width: 300px;
    border: none;
    outline: none;
    padding: 7px 0 7px 1.4rem;
    border-radius: 31px 0 0 31px;
    font-family: $primary_font1;
    &::placeholder {
      color: $grey8;
      font-family: $primary_font1;
    }
  }
  .searchIcon {
    color: $grey7;
    cursor: pointer;
  }
}

.btnSubmit {
  border: none;
  background-color: transparent;
}

@import '../../theme/palette';
@import '../../theme/text';
@import '../../theme/responsive';
$paddingLeft: 1.6rem;

:global(#app) {
  .topRowWrapper {
    height: 6vh;
    max-height: 70px;
    min-height: 60px;
    border-bottom: 1px solid $grey5;
  }

  .menuLogoColumn {
    padding: 0;
    border-radius: 0 !important;
    background: white;
    border-left: 1px solid $grey1;
  }
  .noBg {
    background-color: transparent;
  }
  .p0 {
    padding: 0;
  }
  .m0 {
    margin: 0;
  }
  .icon {
    font-size: 1.1rem;
    height: 1.1rem;
    color: inherit;
  }

  .menuButton {
    width: 100%;
    height: 100%;
    border-radius: 0;
    background: white;
    padding: 0;
  }
  .currentTitleIcon {
    color: $blue5;
    font-size: 1.3rem;
  }

  .notifiedBell {
    color: $nobel;
    font-size: 1.2rem;
  }

  .notifiedMail {
    color: $nobel;
    font-size: 1.3rem;
  }

  .userIcon {
    font-size: 2.3rem;
    color: $nobel;
    height: 1rem !important;
    @include Responsive(XL) {
      font-size: 2rem;
    }
  }

  .logoColumn {
    background-color: $wildSand;
    display: grid;
    place-items: center;
    border-right: 1.5px solid $grey5;
  }
  .topMidColumn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1.2rem 0 1rem;
    border-right: 1.5px solid $information;
  }
  .userRightColumn {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 0.3rem;
    max-height: 60px;
    cursor: pointer;
  }

  .botRowWrapper {
    min-height: 94vh;
  }

  .active {
    background-color: $primary_red1;
    color: $other_white1;
    border-radius: 5px;
    &::before {
      background-color: $other_white1;
    }
  }
}

.wrapper {
  min-height: 100vh;
}

.logo {
  width: 12rem;
}

.currentTitle {
  @include head2;
  color: $blue5;
  letter-spacing: 1px;
  padding-left: 5px;
}

.notifiedBlock {
  display: flex;
  justify-content: center;
  align-items: center;
  .notifiedIconBlock {
    position: relative;
    cursor: pointer;
    .notifiedNumber {
      @include overlineBold;
      color: $other_white1;
      background-color: $primary_red1;
      width: 15px;
      height: 15px;
      line-height: 15px;
      margin: 0;
      text-align: center;
      border-radius: 50%;
      position: absolute;
      top: -7px;
    }
    .notifiedNumberPosition1 {
      right: -6px;
    }
    .notifiedNumberPosition2 {
      right: -10px;
    }
  }
}

.menuButtonIcon {
  margin: 0 !important;
}
.userMenuCollapseBlock {
  position: relative;
  cursor: pointer;
}

.userName {
  @include StronglyEmphasize;
}
.userTitle {
  @include mediumCaption;
  color: $conversation;
}
/* SIDE NAV */
@mixin blockAlign {
  padding-top: 0.8rem;
}

.sideNav {
  height: 100%;
  background-color: $other_white1;
  border-right: 1px solid $grey5;
}
.navDashboard {
  display: flex;
  align-items: center;
  padding: 0.5rem 0;
}

.navClient {
  @include blockAlign;
  border-top: 1px solid $grey4;
}
.navProgram {
  @include blockAlign;
  border-top: 1px solid $grey4;
}
.navCoach {
  @include blockAlign;
  border-top: 1px solid $grey4;
}
.navResource {
  @include blockAlign;
  border-top: 1px solid $grey4;
}

.navLinkWrapper {
  width: 100%;
  padding: 0 0.5rem;
  &:last-child {
    padding-bottom: 1.5rem;
  }
}

.itemBlock {
  display: flex;
  width: 100%;
  padding: 5px 5px 5px 1rem;
  margin: 5px;
  color: $grey6;
  &:hover {
    color: $grey6;
  }
  p {
    @include body1;
  }
}

.categoryTitle {
  margin-bottom: 0;
  position: relative;
  padding-left: $paddingLeft;
  letter-spacing: 1px;
  @include head2;
  color: $grey1;
  /*dash*/
  &::before {
    content: '';
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: $grey1;
    position: absolute;
    top: 40%;
    left: 12px;
  }
}

.categoryText {
  @include body1;
  color: inherit;
  padding-left: 5px;
  cursor: pointer;
}

.contentContainer {
  width: 100%;
  height: 100%;
  background-color: $wildSand;
  padding: 0;
  margin: 0;
}

.tableauNavLinkWrapper {
  padding: 1.2rem 8px;
  .tableauNavItem {
    display: flex;
    color: $grey1;
    @include head2;
    padding: 5px 0;
    position: relative;
    &::before {
      content: '';
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background-color: $grey1;
      position: absolute;
      top: 40%;
      left: 4px;
    }
  }
  .textTableau {
    padding-left: 14px;
    letter-spacing: 1px;
    color: inherit;
  }
}

@media screen and (max-width: $LG) {
  :global(#app) {
    .topRowWrapper {
      position: fixed;
      top: 0;
      z-index: 1000;
      background: white;
      border-bottom: 1px solid $information;
    }
    .menuColumn {
      padding: 0;
    }
    .userRightColumn {
      border-right: 1px solid $information;
      .userInfo {
        display: none;
      }
    }
    .menu {
      position: fixed;
      left: 0;
      transform: translateX(-100%);
      z-index: 100;
      transition: all 1s ease;
      height: calc(100vh - 60px);
      bottom: 0;
      overflow-y: scroll;
      background: white;
      &.open {
        transform: translateX(0);
      }
    }
    .contentContainer {
      margin-top: 60px;
    }
  }
}

@media screen and (min-width: $LG) {
  .menu {
    .logo {
      display: none;
    }
  }
}
@media screen and (max-width: $LG) {
  :global(#app) {
    .logo {
      padding: 20px;
      margin: 0 auto;
      display: block;
    }
    .userRightColumn {
      justify-content: center;
      .userMenuCollapseBlock {
        > .icon {
          display: none;
        }
      }
    }
  }
}

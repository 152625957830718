@import "../../theme/palette";
@import "../../theme/text";
.searchWrapper {
  width: max-content;
  height: 100%;
  background-color: $other_white1;
  padding-right: 0.2rem;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  border: 1px solid $grey4;
  min-height: 2.71428571em;
  display: flex;
  align-items: center;
}

.searchBar {
  font-family: $primary_font1;
  width: 300px;
  border: none;
  outline: none;
  padding: 7px 0 7px 1.4rem;
  border-radius: 31px 0 0 31px;
  &::placeholder {
    color: $grey8;
    font-family: $primary_font1;
  }
}
.searchIcon {
  color: $grey7;
  cursor: pointer;
}

.btnSubmit {
  border: none;
  background-color: transparent;
}

@import "../../theme/palette";
@import "../../theme/text";
@import "../../theme/sharedDashboardContent";

.wrapper{
  @include sharedDashboardContent_desc_padding;
}

.headingContainer {
  margin-top: 1rem;
  .title {
    @include sharedDashboardContent_title;
  }
  .titleDesc {
    @include sharedDashboardContent_desc;
  }
}

@import "../../theme/palette";
@import "../../theme/text";
@import "../../theme/sharedDashboardContent";
@import "../../theme/sharedForm";
@import "../../theme/table";

:global(#app) {
  .dropDown {
    &:nth-child(odd) {
      margin-right: 0.5rem;
    }
    width: 10rem;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    border: 1px solid $grey4;
  }
  .sortIcon {
    @include table_sortIcon;
  }
}

.wrapper {
  margin-top: 1rem;
  width: 100%;
  height: 100%;
  @include sharedForm_formWrapper;
  background-color: $wildSand;
  box-shadow: none;
  padding: 0;
  @include sharedDashboardContent_desc_padding;
  padding-bottom: 1.2rem;
}

.headingContainer {
  margin: 1rem 0;
  .title {
    @include sharedDashboardContent_title;
  }
}

/*TOOLS*/

.toolsWrapper {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-wrap: wrap;
}

.rightToolsModuleBlock {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  justify-content: flex-end;
}
.toolsBlock {
  display: flex;
  align-items: flex-end;
  height: 100%;
  margin-top: 1rem;
  margin-right: 1rem;
}

/*TABLES*/
.tableWrapper {
  overflow-y: auto;
  margin-top: 0.8rem;
  box-shadow: 2px 0px 9px rgba(40, 40, 40, 0.25);
  border-radius: 8px;

  .table {
    width: 100%;
    text-align: center;
    border-collapse: collapse;
    border-radius: 8px 8px 0 0;
    .fixedHeader {
      .headerTR {
        height: 3.5rem;
      }
    }
  }
}

.headerTH {
  @include table_headerTH;
}

.headerTitleBlock {
  @include table_headerTitleBlock;
}

.selectTH {
  width: 5%;
}

.titleTH {
  width: 35%;
}

.formatTH {
  width: 20%;
}

.thematicTH {
  width: 25%;
}

.languageTH {
  width: 15%;
}

.row {
  &:nth-child(odd) {
    background-color: $other_white1;
  }
  &:nth-child(even) {
    background-color: $grey10;
  }
  td {
    &:not(:first-child) {
      padding: 12px 16px;
      text-align: center;
      @include body1;
    }
    ul {
      padding-left: 5px;
      list-style: none;
      li {
        height: 45px;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }
  }
}

.textName {
  color: $other_black1;
}

.selected {
  background-color: $secondary_blue3 !important;
}

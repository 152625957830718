@import "../../theme/palette";
@import "../../theme/text";
@import "../../theme/sharedDashboardContent";
@import "../../theme/sharedForm";
@import "../../theme/responsive";

.wrapper {
  @include sharedDashboardContent_desc_padding;
  position: relative;
}

.clientName{
@include sharedDashboardContent_title;
padding-top: 2rem;
padding-bottom: 1.2rem;
padding-left: 0.5rem;
}

.wrapperBottomBlock {
  @include sharedForm_formWrapper;
  padding-bottom: 1.7rem;
}

.formTitle {
  @include sharedForm_formTitle;
}

.noManagerText {
  @include body1;
  font-weight: 600;
  font-size: 18px;
  text-align: center;
  padding-top: 1.2rem;
}

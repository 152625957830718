/*PRIMARY*/

$primary_blue1: #2f80ed;
$primary_red1: #eb5757;

/*SECONDARY*/
$secondary_blue2: #4292ff;
$secondary_blue3: #bbe7ff;
$secondary_red_MPB: #ed1e24;
$secondary_grey_MPB: #a7a9ac;

/*ACCENT - BACKGROUND*/
$silverTree: #61b885;
$wildSand: #f5f5f5;

/*OTHER*/
$other_yellow1: #fdc002;
$other_white1: #ffffff;
$other_black1: #222222;
$other_dark_red_MPB: #ca1319;
$pdfRed : #F40F02;

/*Others : unsorted, most from dashboard*/
$white: #ffffff;
$strongPinkRed: #c22c40;
$nobel: #979797;
$information: #dfdfdf;
$conversation: #9a9a9a;
$lightBlue: #69a5d1;
$brightBorder: #8ebbdc;
$activeBlue: #4593cc;
$greyText: #cdcdcd;
$greyText2: #616161;
$navyBlue: #1e2947;
$greyLegend: #696969;
$greyTotal: #737373;
$lineChartPink: #fa6767;
$deeperGreen: #42ac6e;

/*GREY*/
$grey1: #414141;
$grey2: #848484;
$grey3: #c4c4c4;
$grey4: #e0e0e0;
$grey5: #b1b3b5;
$grey6: #787878;
$grey7: #dadada;
$grey8: #acacac;
$grey9: #9a9a9a;
$grey10: #f2f2f2;
$grey11: #bdbdbd;
/*RED*/
$red1: #ed1e24;
$red2: #e9374d;

/*BLUE*/
$blue1: #67a9d9;
$blue2: #5cbeff;
$blue3: #bbe7ff;
$blue4: #a4dafe;
$blue5: #2f80ed;
$blue6: #202f58;

/*GREEN*/
$green1: #2da850;

/*YELLOW*/
$yellow1: #ffc000;

@import "../../theme/palette";
@import "../../theme/text";

/*TABLE*/

.table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 1rem;
    th {
      text-align: left;
      border: 1px solid $other_black1;
      padding: 1rem 1rem;
      @include StronglyEmphasize;
      font-size: 18px;
    }
    td {
      border: 1px solid $other_black1;
      padding: 0.5rem 1rem;
    }
    ul {
      padding: 0 1rem;
      li {
        padding: 0.2rem 0;
        @include body1;
      }
    }
  }
  
  .categoryTH {
    width: 30%;
  }
  
  .criteriaTH {
    width: 70%;
  }
  
  .position {
    @include StronglyEmphasize;
    margin-bottom: 0.1rem;
  }
  
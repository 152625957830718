@import "../../theme/text";
.tbodyTD {
  padding: 12px 16px;
  text-align: center;
  @include body1;
}
.programNameText {
  cursor: pointer;
  @include body1;
  font-size: inherit;
  font-weight: inherit;
  color: $other_black1;
}
.client {
  font-weight: 600;
}
.boldNumber {
  font-size: 18px;
  font-weight: 600;
}

.boldText {
  font-size: 18px;
  font-weight: 600;
}

@import "../../theme/palette";
@import "../../theme/text";
@import "../../theme/button";

:global(#app) {
  .closeIcon {
    position: absolute;
    top: 10px;
    right: 5px;
    color: $other_white1;
    font-size: 2.2rem;
    cursor: pointer;
  }
  .btn {
    width: 25%;
    max-width: 12rem;
    outline: none;
    cursor: pointer;
    padding: 15px 0;
    border: none;
  }
  .cancelBtn {
    @include overline;
    @include secondary_btn;
  }
  .agreeBtn {
    @include tertiary_btn;
    background-color: $other_white1;
    margin-left: 1rem;
  }
  .closeBtn {
    @include primary_btn;
  }
  .checkIcon {
    color: $green1;
    font-size: 4rem;
  }

  .dropdownSelectionCoach {
    max-width: 30em;
  }
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  width: 100%;
  height: 100%;
  padding: 10px;
  background: rgba(0, 0, 0, 0.1);
  opacity: 1;
  display: flex;
  justify-content: center;
}

.wrapper {
  text-align: center;
  width: 80%;
  max-width: 72rem;
  min-height: 22rem;
  box-shadow: 0px 3px 10px 2px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  position: absolute;
  top: 20%;
  background-color: $other_white1;
  .title {
    color: $other_white1;
    @include head2;
    font-size: 24px;
    background-color: $secondary_red_MPB;
    padding: 2rem 0;
    border-radius: 8px 8px 0 0;
    margin-bottom: 0;
  }
  .contentWrapper {
    padding: 0 1.2rem;
    text-align: left;
    .contentQuestion {
      @include head2;
      line-height: 35px;
      padding-top: 0;
      font-size: 24px;
    }
    .content {
      @include head1;
    }

    .primaryText {
      color: $secondary_blue2;
      font-size: 24px;
    }
  }

  .alignCenter {
    text-align: center;
  }

  .buttonsWrapper {
    display: flex;
    justify-content: flex-end;
    margin-top: 2rem;
    padding: 0 1rem 0 1rem;
    span {
      @include head2;
    }
  }
}

.bottomWrapper {
  padding: 1rem 0 2rem 0;
}

.text2 {
  font-family: $primary_font1;
  font-size: 24px;
  font-weight: 400;
}

@import "../../theme/palette";
@import "../../theme/text";
@import "../../theme/sharedDashboardContent";
@import "../../theme/sharedForm";

.wrapper {
  position: relative;
  height: 100%;
  @include sharedDashboardContent_desc_padding;
  padding-bottom: 2rem;
}

.headingContainer {
  margin: 1rem 0;
}
.title {
  @include sharedDashboardContent_title;
}
.titleDesc {
  @include sharedDashboardContent_desc;
}

.contentWrapper {
  width: 100%;
}

.PMListHeaderBlock {
  margin: 1.2rem 0 1rem 0;

  @include sharedForm_formWrapper;
  padding-bottom: 1rem;
}

:global(#app) {
  .customLastGrid {
    margin-top: 0;
  }
  .courseListWrapper {
    @include sharedForm_formWrapper;
    padding-bottom: 1rem;
  }
}

.courseListTitle {
  @include sharedForm_formTitle;
}

@import "../../theme/palette";
@import "../../theme/text";
@import "../../theme/button";
@import "../../theme/sharedForm";
@import "../../theme/responsive";

:global(#app) {
  .editBtn {
    @include sharedForm_editBtn;
  }

  .btnCancel {
    @include sharedForm_btnCancel;
    margin-top: 1rem;
    width: $form_save_cancel_btn_width;
  }
  .btnSave {
    @include sharedForm_btnSave;
    width: $form_save_cancel_btn_width;
  }

  .active {
    @include sharedForm_activeSaveBtn;
  }
  .disable {
    @include primary_disabled_btn;
  }

  .rightIcon {
    color: $secondary_grey_MPB;
    cursor: pointer;
    text-align: left;
  }

  .checkbox {
    label {
      @include body1;
      font-size: 16px;
    }
  }

  .radioBox {
    label {
      @include body1;
      font-size: 16px;
    }
  }
  .thematicQuestion {
    margin-bottom: 1.5rem;
    label {
      font-size: 18px;
    }
  }
  .pencilIcon {
    @include sharedForm_pencilIcon;
  }
}

.editText {
  @include sharedForm_editText;
}

.formWrapper {
  margin-top: 1.2rem;
  position: relative;

  @include sharedForm_formWrapper;
}

.formTitle {
  @include sharedForm_formTitle;
}

.formContainer {
  display: flex;
}

.formCreateCoach {
  width: 70%;
  .formRow {
    position: relative;
  }
  .formField {
    @include sharedForm_formField;
    align-items: center;
    .w42 {
      width: 42%;
    }
    .w66 {
      width: 66%;
    }
    .labelWrapper {
      width: $labelWidth;
      .label {
        @include body1;
        font-weight: 600;
      }
    }
    .radioLabel {
      width: 100%;
    }
  }

  .noBorderBottom {
    border-bottom: none;
  }

  .positioningInput {
    position: absolute;
    bottom: 0;
    right: 0;
  }

  .sup {
    color: $red1;
    font-size: 18px;
    font-weight: bold;
    top: 0;
  }
  .inputField {
    @include sharedForm_inputField;
  }
}

.checkboxWrapper {
  width: 75%;
}

.radioWrapper {
  margin-top: 1rem;
}

.languagesViewItem {
  font-family: $primary_font1;
  color: $other_black1;
  margin-right: 1rem;
}

.certificationViewItem {
  font-family: $primary_font1;
  color: $other_black1;
  margin-right: 1rem;
}

/**RIGHT GROUP**/

.rightGroup {
  @include sharedForm_rightGroup;
  width: 30%;
  justify-content: flex-start;
  align-items: flex-end;
  padding-top: 2rem;
  .btnGroup {
    text-align: center;
    display: flex;
  }
}

.buttonRightGroup {
  @include Responsive(MD) {
    display: none;
  }
}

.buttonBottomGroup {
  text-align: right;
  display: none;
  @include Responsive(MD) {
    display: block;
  }
}

.textErrorMessage {
  @include sharedForm_dangerMessage;
}

/*MANUAL GUIDE*/

.instructionWrapper {
  margin-top: 1.2rem;
}

.instructionDropDown {
  display: flex;
  align-items: center;
}

.instructionTextBlock {
  @include StronglyEmphasize;
  font-size: 18px;
  background-color: $grey4;
  width: 100%;
  padding: 0.4rem 0.5rem;
}

/*THEMATICS*/

.thematicsWrapper {
  margin-top: 1.2rem;
}

.thematicsTitle {
  @include StronglyEmphasize;
  font-size: 18px;
  padding: 0.5rem 1rem 0.5rem 3.2rem;
  background-color: $primary_blue1;
  color: $other_white1;
}

.thematicsQuestionWrapper {
  margin-top: 1.2rem;
  padding: 1.5rem 0;
  border: 1px solid $grey4;
  border-radius: 10px;
}

.questionCategory {
  @include StronglyEmphasize;
  font-size: 18px;
  border-bottom: 1px solid $grey4;
  padding: 0 1.5rem 1rem 1.5rem;
}

.thematicsQuestionBlock {
  padding: 0 1.5rem;
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: $MD) {
  .formContainer {
    display: block;
  }
  .formCreateCoach .positioningInput {
    position: relative;
  }
  .inputSearchWrapper,
  .formCreateCoach,
  .rightGroup {
    width: 100%;
  }
  .rightGroup {
    margin-top: 20px;
  }
}

.previewWrapper {
  margin-top: 2.2rem;
}

.presentationField {
  align-items: flex-start !important;
  padding-bottom: 10px !important;
}

.presentationOutline {
  border: 1px solid $secondary_grey_MPB !important;
  padding: 10px;
  border-radius: 5px;
  width: 75% !important;
  @include Responsive(MD) {
    width: 100% !important;
    margin-top: 1rem;
  }
}

.photoPositionLeft {
  @include Responsive(MD) {
    display: none;
  }
}

.photoPositionTop {
  max-width: 190px;
  display: none;
  @include Responsive(MD) {
    display: block;
  }
}

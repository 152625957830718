@import "../../theme/palette";
@import "../../theme/text";
@import "../../theme/responsive";

.wrapper {
  display: flex;
  align-items: center;
}

:global(#app) {
  .currentTitleIcon {
    color: $primary_blue1;
  }

  .currentTitle {
    @include head2;
    color: $primary_blue1;
    letter-spacing: 1px;
    padding-left: 5px;
  }

  .infoIcon {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 2px solid $primary_blue1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (max-width: $MD) {
  :global(#app) {
    .currentTitleIcon {
      display: none;
    }
  }
}
